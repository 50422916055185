import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { axiosPlugin } from './plugins/axios'
import axios from 'axios'
import { createPinia } from 'pinia'

import "@/assets/scss/theme.scss"

import Swal from "sweetalert2";
import ErrorComponent from './layouts/error.vue'
import 'sweetalert2/dist/sweetalert2.min.css';

import { authModule } from "@/stores/auth.js";
import tooltip from "@/directives/tooltip.js";
import popover from "@/directives/popover.js";
import phone from "@/directives/phone.js";
import trn from "@/directives/trn.js";

const app = createApp(App)

// Directives
app.directive('tooltip', tooltip)
app.directive('popover', popover)
app.directive('phone', phone)
app.directive('trn', trn)

app.config.errorHandler = (err, vm, info) => {
  console.error(`Error: `, err);
  app.mount('#app', {
    render: () => h(ErrorComponent, { props: { errorMessage: err.message }})
  });
}

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? "https://farintoyaadadmin.com" : "http://localhost:1007",
  withCredentials: false
})

axiosInstance.interceptors.request.use(function (config) {
  config.headers.Authorization =  'Bearer ' + localStorage.getItem('token');
  return config;
});

app.config.globalProperties.$swal = Swal

const pinia = createPinia()
pinia.use(({ store }) => {
  store.$axios = axiosInstance
  store.$router = router
})

// Adds instances to set up
app.provide('swal', Swal);

app.use(pinia)
app.use(router)
app.use(axiosPlugin)

const authStore = authModule()
authStore.persistUser()

app.mount('#app')