<script setup>
  import { RouterView } from 'vue-router'
  import layouts from "./layouts.js";
  import { shallowRef, provide, onMounted } from "vue";
  import router from "./router/index.js";

  const layout = shallowRef('div')
  router.afterEach((to) => {
    layout.value = layouts[to.meta.layout] || 'div'
  })

  provide('app:layout', layout)
</script>

<template>
  <component :is="layout || 'div'">
    <RouterView />
  </component>
</template>

<style scoped>

</style>
