<script>
export default {
  name: "feet"
}
</script>

<template>
  <footer class="footer bg-dark fixed-bottom">
    <div class="border-top border-light">
      <div class="container py-4">
        <div class="fs-xs text-light">
          <span class="fs-sm">© </span>
          All rights reserved. Made with
          <i class="ci-heart mt-n1 mx-1 fs-base text-primary align-middle"></i>
          <a href="#" class="text-light" target="_blank" rel="noopener noreferrer">by Xander Studios</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>

</style>