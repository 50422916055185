import { Popover } from "bootstrap";

export default {
  mounted(el, binding) {
    el._popover = new Popover(el, {
      placement: binding.arg || 'top',
      content: binding.value || '',
      trigger: 'hover',
      title: el.getAttribute('title') || ''
    });
  },

  unmounted(el) {
    if (el._popover) {
      el._popover.dispose();
      delete el._popover;
    }
  }
}