import { Tooltip } from "bootstrap";

export default {
  mounted(el, binding) {
    const placement = Object.keys(binding.modifiers).find(mod => binding.modifiers[mod]) || 'top';

    el._tooltip = new Tooltip(el, {
      placement: placement,
      title: binding.value
    });
  },

  unmounted(el) {
    if (el._tooltip) {
      el._tooltip.dispose();
      delete el._tooltip;
    }
  }
}