export default {
  mounted(el) {
    const handleInput = (event) => {
      let phone = event.target.value.replace(/\D/g, '').substring(0, 10);
      event.target.value = phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    };

    el.addEventListener('input', handleInput);
    el._handleInput = handleInput;
  },
  beforeUnmount(el) {
    el.removeEventListener('input', el._handleInput);
    delete el._handleInput;
  }
}
